import { link, tokenLink, dnsPortal, urlADM } from "../../services/variables";

//alguns link possuem token diferente do padrão
const linksNavbar = [
  //Município
  {
    name: `Município`,
    top_list: true,
    img: "Municipio",
    sub_link: [
      {
        name: `TV Câmara`,
        link: `/municipio/tv-camara`,
      },
      {
        name: `Distâncias`,
        link: `/municipio/distancias`,
      },
      {
        name: `História`,
        link: `/municipio/historia`,
      },
      // {
      //   name:`População`,
      //   link:`/municipio/numeros`
      // },
      {
        name: `Mapa`,
        link: `/municipio/mapa`,
      },
      {
        name: `Símbolos`,
        link: `/municipio/simbolos`,
      },
      {
        name: `Endereços Úteis`,
        link: `/municipio/enderecos-uteis`,
      },
      // {
      //   name:`Distritos`,
      //   link:`/municipio/distritos`
      // },
      // {
      //   name:`Eventos`,
      //   link:`/municipio/eventos`
      // },
      // {
      //   name:`Obras`,
      //   link:`${link}/portaltm/licitacao/obrapublica.xhtml?token=${tokenLink}`
      // },
      // {
      //   name:`Veículos`,
      //   link:`/municipio/veiculos`
      // },
    ],
  },
  //Vereadores
  {
    name: `Vereadores`,
    top_list: true,
    img: "justice",
    sub_link: [
      {
        name: `Registro das Competências`,
        link: `/vereadores/registro-das-competencias`,
      },
      {
        name: `População X Vereadores`,
        link: `/vereadores/populacao-x-vereadores`,
        // link:`#`,
      },
      {
        name: `Termo de Posse dos Vereadores`,
        link: `${link}/portaltm/atosadministrativos/index.xhtml?token=${tokenLink}&tipo=Termo%20de%20Posse%20dos%20Vereadores`,
        // link:`#`,
      },
    ],
  },
  //Legislativo Municipal
  {
    name: `Legislativo Municipal`,
    top_list: true,
    img: "Institucional",
    sub_link: [
      {
        name: `Mural`,
        link: `/legislativo-municipal/mural`,
      },
      {
        name: `Estrutura Organizacional`,
        // link: `${link}/portal/organograma/organograma.xhtml?token=${tokenLink}`,
        link: `/estrutura-organizacional`

      },
      {
        name: `Concurso Público`,
        link: `${urlADM}/sacops/concurso?token=${tokenLink}`,
      },
      {
        name: `Legislação`,
        sub_link: [
          {
            name: `Regimento interno`,
            // link: `/legislativo-municipal/legislacao/regimento-interno`,
            link: `${urlADM}/admpublica/regimento-interno?token=${tokenLink}`,
          },
          {
            name: `Lei Orgânica`,
            // link: `/legislativo-municipal/legislacao/lei-organica`,
            link: `${urlADM}/admpublica/lei-organica?token=${tokenLink}`,
          },
          // {
          //   name:`Código Tributário`,
          //   link:`/legislativo-municipal/legislacao/estaduais/codigo-tributario`
          // },
          {
            name: `Leis Municipais`,
            // link: `/legislativo-municipal/legislacao/lei`,
            link: `${urlADM}/admpublica/lei-ordinaria?token=${tokenLink}`,
          },

          // {
          //   name:`Leis Estaduais`,
          //   link:`${link}/portal/transparencia/transparencia.xhtml?token=${tokenLink}`
          // },
          // {
          //   name:`Projeto de Leis`,
          //   link:`/legislativo-municipal/legislacao/estaduais/projeto-de-lei`
          // },
          {
            name: `Resoluções`,
            // link: `/legislativo-municipal/legislacao/resolucoes`,
            link: `${urlADM}/admpublica/resolucoes?token=${tokenLink}`,
          },
          {
            name: `Decretos `,
            // link: `/legislativo-municipal/legislacao/decreto`,
            link: `${urlADM}/admpublica/decreto?token=${tokenLink}`,
          },
          {
            name: `Emendas`,
            // link: `/legislativo-municipal/legislacao/emendas`,
            link: `${urlADM}/admpublica/emendas?token=${tokenLink}`,
          },
          {
            name: `Portarias`,
            link: `${urlADM}/admpublica/portarias?token=${tokenLink}`,
            // sub_link: [
            //   {
            //     name: `Nomeação`,
            //     link: `/legislativo-municipal/legislacao/portarias/nomeacao`,
            //   },
            //   {
            //     name: `Exoneração`,
            //     link: `/legislativo-municipal/legislacao/portarias/exoneracao`,
            //   },
            //   {
            //     name: `Outras`,
            //     link: `/legislativo-municipal/legislacao/portarias/outros`,
            //   },
            // ],
          },
          {
            name: `Outros Atos Administrativos `,
            // link: `/legislativo-municipal/legislacao/outros-atos-administrativos`,
            link: `${urlADM}/admpublica/outros-atos-administrativos?token=${tokenLink}`,
          },
        ],
      },
      {
        name: `Cotas das atividades Parlamentar/Verba`,
        link: `${urlADM}/admpublica/cotas-para-exercicio-da-atividade-parlamentar-verba-indenizatoria?token=${tokenLink}`,
      },
      {
        name: `Legislação relacionada a gastos dos parlamentares`,
        // link: `/legislativo-municipal/legislacao-relacionada-a-gastos-dos-parlamentares`,
        link: `${urlADM}/admpublica/legislacao-relacionada-a-gastos-dos-parlamentares?token=${tokenLink}`,
      },
      {
        name: `Projetos de leis e atos infralegais`,
        // link: `/legislativo-municipal/projeto-legislativo`,
        link: `${urlADM}/admpublica/projetos-e-atividades-legislativas?token=${tokenLink}`,
      },
      {
        name: `Pauta das Comissões e das Sessões do Plenário`,
        // link: `/legislativo-municipal/pauta-das-comissoes-e-das-sessoes-do-plenario`,
        link: `${urlADM}/admpublica/pauta-das-comissoes-e-das-sessoes-do-plenario?token=${tokenLink}`,
      },
      {
        name: `Ata de Sessões`,
        link: `${urlADM}/admpublica/sessoes?token=${tokenLink}`,
        // sub_link: [
        //   {
        //     name: `Solene`,
        //     link: `/legislativo-municipal/ata-de-sessoes/solene`,
        //   },
        //   {
        //     name: `Ordinária`,
        //     link: `/legislativo-municipal/ata-de-sessoes/ordinaria`,
        //   },
        //   {
        //     name: `Extraordinária`,
        //     link: `/legislativo-municipal/ata-de-sessoes/extraordinaria`,
        //   },
        // ],
      },
      {
        name: `Votação nominais e/ou unânimes`,
        // link: `/legislativo-municipal/votacao-nominais-e/ou-unanimes`,
        link: `${urlADM}/admpublica/votacoes-nominais-dos-projetos-de-leis?token=${tokenLink}`,
      },
      {
        name: `Presenças e Ausências dos Parlamentares`,
        // link: `/legislativo-municipal/presencas-e-ausencias-dos-parlamentares`,
        link: `${urlADM}/admpublica/controle-de-frequencia-dos-vereadores?token=${tokenLink}`,
      },
      {
        name: `Julgamento das Contas do Governador/Prefeito`,
        // link: `/legislativo-municipal/julgamento-das-contas-do-governador/prefeito`,
        link: `${urlADM}/admpublica/julgamento-das-contas-anuais?token=${tokenLink}`,
      },
      {
        name: `Resultado Inspeções, prestações e tomadas de contas`,
        // link: `/legislativo-municipal/resultado-inspecoes-prestacoes-e-tomadas-de-contas`,
        link: `${urlADM}/admpublica/resultados-de-inspecoes-auditorias-prestacoes-e-tomadas-de-contas?token=${tokenLink}`,
      },
      // {
      //   name:`Perguntas e Respostas`,
      //   link:`${link}/esic/perguntaresposta.xhtml?token=${tokenLink}&tipo=Perguntas%20e%20Respostas%20e-Sic`
      // },
    ],
  },
  //Diário Oficial
  {
    name: `Diário Oficial`,
    top_list: true,
    img: "Diario_OFI",
    sub_link: [
      {
        name: `Diário Oficial Eletrônico`,
        // link:`${link}/diario/diariov2.xhtml?token=${tokenLink}`
        // link:`/diario-oficial/diario-oficial-eletronico`
        link: "http://www.diarioficialdosmunicipios.org",
      },
      {
        name: `Lei de criação do Diário`,
        // link: `/diario-oficial/lei-de-criacao-do-diario`,
        link: `${urlADM}/admpublica/lei-de-criacao-do-diario-oficial-eletronico-do-municipio?token=9664abfc624b73571a05e874f98fd6d114834924`,
      },
      {
        name: `Ato de nomeação do responsável`,
        // link: `/diario-oficial/ato-de-nomeacao-do-responsavel`,
        link: `${urlADM}/admpublica/ato-de-nomeacao-de-pessoa-responsavel-pelas-publicacoes-do-diario-oficial-do-municipio?token=9664abfc624b73571a05e874f98fd6d114834924`,
      },
    ],
  },
  //Pessoal
  {
    name: `Pessoal`,
    top_list: true,
    img: "Pessoal",
    sub_link: [
      {
        name: `Contracheque`,
        link: `http://www.inforfolha.com.br/inforfolha/contracheque/login.xhtml?token=2c3d2088-3af2-4c5f-b0c6-7d8dcfb76a39`,
      },
      {
        name: `Estatuto do Servidor`,
        // link: `/pessoal/estatuto-dos-servidores`,
        link: `${urlADM}/admpublica/estatuto-dos-servidores?token=${tokenLink}`,
      },
      {
        name: `Folha de pagamento`,
        link: `http://www.inforfolha.com.br/inforfolha/contracheque/remuneracao.xhtml?token=4c2919dc-eadb-4e17-bc94-47441af9e348`,

        // sub_link:[
        //   {
        //     name:`Gestão Atual`, //
        //     link:`#` //link diferente
        //   },
        //   {
        //     name:`Gestão Anterior`,
        //     link:`#`
        //   },
        // ]
      },
      {
        name: `Remuneração Nominal de cada servidor`,
        // link: `/pessoal/remuneracao-nominal-de-cada-servidor`,
        link: `${urlADM}/admpublica/relacao-nominal-de-cada-servidor?token=${tokenLink}`,
      },
      {
        name: `Servidores inativos`,
        // link: `/pessoal/relacao-de-servidores-inativos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-inativos?token=${tokenLink}`,
      },
      {
        name: `Servidores ocupante de cargo em comissão`,
        // link: `/pessoal/relacao-de-servidores-ocupantes-de-cargo-em-comissao`,
        link: `${urlADM}/admpublica/relacao-de-servidores-ocupantes-de-cargo-em-comissao?token=${tokenLink}`,
      },
      {
        name: `Servidores cedidos para outros Órgãos Públicos`,
        // link: `/pessoal/relacao-de-servidores-cedidos-para-outros-orgaos-publicos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-cedidos-para-outros-orgaos-publicos?token=${tokenLink}`,
      },
      {
        name: `Relação nominal dos estagiários`,
        // link: `/pessoal/relacao-nominal-dos-estagiarios`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-estagiarios?token=${tokenLink}`,
      },
      {
        name: `Relação de contratados temporariamente e contratos de terceirização`,
        // link: `/pessoal/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao?token=${tokenLink}`,
      },
      {
        name: `Tabela com o padrão remuneratório dos cargos e função`,
        // link: `/pessoal/tabela-remuneratoria-dos-cargos-e-funcoes`,
        link: `${urlADM}/admpublica/tabela-remuneratoria-dos-cargos-e-funcoes?token=${tokenLink}`,
      },
    ],
  },
  // Diárias
  {
    name: `Diárias`,
    top_list: true,
    img: "Diárias",
    sub_link: [
      {
        name: `Tabela de valores das diárias`,
        // link: `/diarias/tabela-de-valores-das-diarias`,
        link: `${urlADM}/admpublica/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais?token=${tokenLink}`,
      },
      {
        name: `Diárias e Passagens`,
        link: `#`,
      },
      {
        name: `Legislação`,
        // link: `/diarias/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais`,
        link: `${urlADM}/admpublica/diarias-e-legislacao?token=${tokenLink}`,
      },
    ],
  },
  // Transparência
  {
    name: `Transparência`,
    link: `/transparencia`,
    top_list: true,
    img: "Transparência",
  },
  // Licitações e Contratos
  {
    name: `Licitações e Contratos`,
    top_list: true,
    img: "licitaçoes",
    sub_link: [
      {
        name: `Indicação do Fiscal de Contrato`,
        link: `/licitacoes-e-contratos/indicacao-do-fiscal-do-contrato`,
      },
      {
        name: `Avisos`,
        link: `/licitacoes-e-contratos/avisos`,
      },
      {
        name: `Edital`,
        link: `/licitacoes-e-contratos/edital`,
      },
      {
        name: `Dispensas`,
        link: `/licitacoes-e-contratos/dispensas`,
      },
      {
        name: `Inexigibilidade`,
        link: `/licitacoes-e-contratos/inexigibilidade`,
      },
      {
        name: `Pregão Eletrônico`,
        link: `/licitacoes-e-contratos/pregao-eletronico`,
      },
      {
        name: `Ata de Adesão - SRP `,
        link: `/licitacoes-e-contratos/ata-de-adesao`,
      },
      {
        name: `Resultados do Certame`,
        link: `/licitacoes-e-contratos/resultado-do-certame`,
      },
      {
        name: `Desertas e Fracassadas`,
        link: `/licitacoes-e-contratos/desertas-fracassadas`,
      },
      {
        name: `Termo de Aditivo`,
        link: `/licitacoes-e-contratos/termo de-aditivo`,
      },
      // {
      //   name:`Convênio`,
      //   link:`/licitacoes-e-contratos/convenio`
      // },
      {
        name: `Contratos `,
        // link:`${link}/portaltm/contrato/contrato.xhtml?token=${tokenLink}`
        link: `/licitacoes-e-contratos/contrato`,
      },
    ],
  },
  // Ouvidoria
  {
    name: `Ouvidoria`,
    top_list: true,
    img: "Ouvidoria",
    sub_link: [
      {
        name: `Ato de Nomeação - Ouvidor`,
        link: `${urlADM}/admpublica/divisao/regulamentacao-da-ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Atendimento Presencial`,
        link: `/ouvidoria/atendimento-presencial`,
      },
      {
        name: `Carta de Serviços ao Usuário`,
        link: `/ouvidoria/carta-de-servicos-ao-usuario`,
      },
      {
        name: `Redes Sociais`,
        link: `/redes-sociais`,
      },
      {
        name: `Canal Eletrônico de Acesso`,
        // link:`${link}/ouvidoriatm/index.xhtml?token=${tokenLink}`,
        // link: `/ouvidoria/canal-eletronico-de-acesso`,
        link: `${urlADM}/ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Perguntas e Respostas`,
        // link: `/ouvidoria/perguntas-e-respostas`,
        link: `${urlADM}/ouvidoria/acompanhamento?token=${tokenLink}`,
      },
      // {
      //   name:``,
      //   link:`/ouvidoria/`,
      // },

      // {
      //   name:`Detalhe`,
      //   link: `/ouvidoria/detalhe`
      // },
    ],
  },
  // Cidadão | e-SIC
  {
    name: `Cidadão | e-SIC `,
    top_list: true,
    img: "Cidadão",
    sub_link: [
      {
        name: `e-SIC - Regulamentação`,
        // link: `${link}/portal/sic/sic.xhtml?token=${tokenLink}`,
        link: `${urlADM}/esic?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Perguntas e Respostas`,
        link: `${link}/esic/perguntaresposta.xhtml?token=${tokenLink}&tipo=Perguntas%20e%20Respostas%20e-Sic`,
      },
      {
        name: `e-SIC`,
        link: `${link}/esic/index.xhtml?token=${tokenLink}`,
      },
      {
        name: `Instrumento normativo local que regulamente a LAI`,
        // link: `${link}/portal/transparencia/transparencia.xhtml?token=${tokenLink}`,
        link: `${urlADM}/acesso-a-informacao?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Rol de Informações (12 meses)`,
        link: `/cidadao-e-sic/e-sic-rol-de-informacoes(12meses)`,
      },
      {
        name: `e-SIC - Rol de documentos (Grau Sigilo)`,
        link: `/cidadao-e-sic/e-sic-rol-de-documentos(12meses)`,
      },
    ],
  },
  {
    name: `LGPD`,
    top_list: true,
    img: "lgpd",
    link: `${urlADM}/lgpd?token=${tokenLink}`,
  },
  //Usuário
  {
    name: `Usuário`,
    top_list: true,
    img: "admin-navbar",
    sub_link: [
      {
        name: `Login - Portal`,
        link: `/admin/login`,
      },
      {
        name: `Login - Diário`,
        link: `${link}/diario/login.xhtml`,
      },
      {
        name: `Login - Licitação`,
        link: `${link}/licitacaotm/login.xhtml`,
      },
      {
        name: `Login - e-SIC`,
        link: `${link}/esic/adm/login.xhtml?token=${tokenLink}`,
      },
      {
        name: `WebMail`,
        link: `https://webmail-seguro.com.br/${dnsPortal}`,
      },
    ],
  },
];
export default linksNavbar;
